// Home.js
import './Home.css';
import will from './assets/will.webp';
import cf from './assets/cf.png';
import discord from './assets/discord.png';
import git from './assets/git.png';
import instagram from './assets/instagram.png';
import telegram from './assets/telegram.png';
import horn from './assets/mlg.mp3';
import { useState, useRef, useEffect, Suspense } from 'react';
import Zoom from 'react-reveal/Zoom'; 
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Reveal from 'react-reveal/Reveal';
import CardSlider from "./CardSlider/CardSlider";
import CardSlider2 from "./CardSlider/CardSlider2";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, PointLight  } from '@react-three/drei';
import { useGlitch } from 'react-powerglitch'
import { useParallax } from 'react-scroll-parallax';
import LaptopWrapper from '../file/LaptopWrapper'; // Importa o novo componente
import robozao from './assets/robozao.png'
import chara from './assets/chara.png'

function Home() {
    const [isSticky, setIsSticky] = useState(true); // Estado para controlar se é sticky
    const [isHeight, setHeight] = useState(true); // Estado para controlar se é sticky
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef(null);
    const slideLabels = ["Desenvolvimento", "Experiência", "Habilidades", "Projetos", "Contato"];

    // Ensure the first slide is visible on mount
    useEffect(() => {
        const items = carouselRef.current.children;
        if (items.length > 0) {
            // Remove 'show' class de todos os itens
            for (let i = 0; i < items.length; i++) {
                items[i].classList.remove('show');
            }
            // Adiciona 'show' apenas ao primeiro item
            items[0].classList.add('show');
        }
    }, []);

    const handleScroll = (event) => {
        // Check if the event is real and has preventDefault method
        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
    
        const scrollAmount = event.deltaY || 0; // Use event.deltaY or fallback to 0
    
        const itemWidth = carouselRef.current.scrollWidth / carouselRef.current.childElementCount;
        const currentIndex = Math.round(carouselRef.current.scrollLeft / itemWidth);
        const nextIndex = Math.max(0, Math.min(carouselRef.current.childElementCount - 1, currentIndex + Math.sign(scrollAmount)));
    
        setCurrentSlide(nextIndex);
        setIsSticky(nextIndex < 4);
        setHeight(nextIndex < 4);
    
        // Add 'show' class to the current item
        const items = carouselRef.current.children;
        for (let i = 0; i < items.length; i++) {
            if (i === nextIndex) {
                items[i].classList.add('show');
            } else {
                items[i].classList.remove('show');
            }
        }
    
        let startTime = null;
        const stickyScrollLeft = nextIndex * itemWidth;
    
        const animateScroll = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const easing = Math.min(elapsed / 2000, 1);
            const easeInOutQuad = easing < 0.5 ? 2 * easing * easing : -1 + (4 - 2 * easing) * easing;
    
            const currentScrollLeft = carouselRef.current.scrollLeft;
            const distance = stickyScrollLeft - currentScrollLeft;
            const step = distance * easeInOutQuad;
    
            carouselRef.current.scrollLeft += step;
    
            if (Math.abs(distance) > 1) {
                requestAnimationFrame(animateScroll);
            } else {
                carouselRef.current.scrollLeft = stickyScrollLeft;
            }
        };
    
        requestAnimationFrame(animateScroll);
    };
    const [startY, setStartY] = useState(0); // Adicionando o estado para Y
    const [isDragging, setIsDragging] = useState(false); // Para controlar se o usuário está arrastando
    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY); // Posição inicial do toque
        setIsDragging(true); // Define que está arrastando
    };

    const handleTouchMove = (event) => {
        if (!isDragging) return; // Verifica se está arrastando
    
        const currentY = event.touches[0].clientY;
        const diffY = startY - currentY;
    
        // Verifica a direção do arrasto
        if (diffY > 50) {
            // Arrastou para cima, avance o slide
            handleScroll({ deltaY: 1, preventDefault: () => {} }); // Mocking preventDefault
            setIsDragging(false); // Para evitar múltiplas chamadas
        } else if (diffY < -50) {
            // Arrastou para baixo, retroceda o slide
            handleScroll({ deltaY: -1, preventDefault: () => {} }); // Mocking preventDefault
            setIsDragging(false); // Para evitar múltiplas chamadas
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false); // Finaliza o arrasto
    };

    const glitch = useGlitch();

    return (
        <main className="content">
            
            <div className="title-container">
                <div className="title">
                    
                    <Fade delay={201}>
                    <Canvas camera={{ position: [-1, 1, 3.5], fov: 52 }}>
                        <ambientLight intensity={1} color="#fc5203" />
                        <OrbitControls 
                            enableZoom={false}
                            enablePan={true} // Permite mover a cena
                            minPolarAngle={Math.PI / 4} // Limita a rotação para baixo
                            maxPolarAngle={Math.PI / 2} // Limita a rotação para cima
                            minAzimuthAngle={-Math.PI / 4} // Limita a rotação para a esquerda
                            maxAzimuthAngle={Math.PI / 4} // Limita a rotação para a direita
                        />
                        <Suspense fallback={null}>
                            <LaptopWrapper/> {/* Passa a posição do mouse */}
                        </Suspense>
                        <Environment preset='city' />
                    </Canvas>
                    </Fade>
                    <Fade delay={501} duration={3000}>
                    <label className="name">GUS</label>
                    <label className="name2">TAVO</label>
                    </Fade>
                    <Fade delay={1001} duration={4000}>
                        <label className="name3"><span ref={glitch.ref}>LASS</span></label>

                        <div className="divider"></div>
                        <div className="undertext-div">
                        <label className="undertext">Full-Stack Developer - Bacharelado em Ciência da Computação pela UDESC. <br /> 18 Anos, 7 anos+ de experiência em desenvolvimento</label>
                        </div>
                    </Fade>
                    <Fade delay={501}>
                        <ul className="buttons"> 
                            <li><a target="_blank" href="https://github.com/D4rkks"><img title="Github" className="img" src={git} /></a></li>
                            <li><a target="_blank" href="https://codeforces.com/profile/D4rkks"><img title="Codeforces" className="img" src={cf} /></a></li>
                            <li><a target="_blank" href="https://discord.com/users/247726496435666944/"><img title="Discord" className="img" src={discord} /></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/gustavo.lass/"><img title="Instagram" className="img" src={instagram} /></a></li>
                            <li><a href=""><img title="Telegram" className="img" src={telegram} /></a></li>
                        </ul>
                    </Fade>
                </div>
            </div>


            <div className="skills">
                <div className={`carroussel ${isHeight ? 'defaultHeight' : 'afterHeight'}`} >
                <div className={`contentContainer ${isSticky ? 'sticky' : 'unsticky'}`} >
                        <label className="title">HABILIDADES</label>
                        <div 
                            className="carousel" 
                            onWheel={handleScroll} 
                            onTouchStart={handleTouchStart} // Adiciona evento de toque
                            onTouchMove={handleTouchMove} // Adiciona evento de movimento de toque
                            onTouchEnd={handleTouchEnd} // Adiciona evento de finalização de toque
                            ref={carouselRef}
                        >
                            <div className="carousel-item show">
                                <label className="titletext">Sou um programador júnior com vastas habilidades em desenvolvimento front-end e back-end, 
                                    criação de bots e engenharia de software. Tenho experiência em várias linguagens e frameworks, 
                                    buscando sempre expandir meu conhecimento e aplicar boas práticas de codificação. Com paixão por 
                                    resolver problemas e focado na experiência do usuário.</label>
                                <label className="dev01">01</label>
                                <label className="dev">Desenvolvimento</label>
                                <label className="description">Minha experiência como desenvolvedor começou com desenvolvimento de bots para o Discord, 
                                    onde criei sistemas de atendimento automático por volta de 2016/2018. 
                                    Desde 2017, atuo também como freelancer no desenvolvimento de front-ends para websites, 
                                    sempre buscando atender às necessidades dos clientes. Entre 2020 e 2024, 
                                    trabalhei no desenvolvimento avançado de cheats para jogos, 
                                    aplicando técnicas de bypass em sistemas de anti-cheat e adquirindo habilidades em engenharia reversa 
                                    e manipulação de memória. Atualmente, sou estudante de Ciência da Computação na UDESC, 
                                    onde continuo a expandir meus conhecimentos em programação e desenvolvimento de software.</label>
                                    <div className="robozao">
                                    <div className="robozao-fade"></div>
                                    <img 
                                        src={robozao} 
                                        alt="Im Jobless" 
                                        className="robozao-img" 
                                    />
                                    </div>
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext2">Sou um programador júnior com vastas habilidades em desenvolvimento front-end e back-end, 
                                    criação de bots e engenharia de software. Tenho experiência em várias linguagens e frameworks, 
                                    buscando sempre expandir meu conhecimento e aplicar boas práticas de codificação. Com paixão por 
                                    resolver problemas e focado na experiência do usuário.</label>
                                <label className="dev02">02</label>
                                <label className="dev2">Experiência</label>
                                <label className="description2">Minha experiência conta principalmente com proficiência em C++/C, tendo me concentrado, 
                                    nos últimos anos, no desenvolvimento de baixo nível, 
                                    como a criação de drivers para sistemas e sistemas embarcados (Arduino, Kmbox, etc.). 
                                    Além disso, tive contato com Java para o desenvolvimento de plugins para Minecraft. 
                                    Também tenho experiência com AWS e Google Cloud, onde criei e mantive um servidor de Minecraft por cerca de um ano, sendo o único desenvolvedor responsável. 
                                    Essa vivência me proporcionou um entendimento aprofundado em gestão de servidores e escalabilidade de aplicações..</label>

                            </div>
                            <div className="carousel-item show">
                            <label className="titletext3">Sou um programador júnior com vastas habilidades em desenvolvimento front-end e back-end, 
                                    criação de bots e engenharia de software. Tenho experiência em várias linguagens e frameworks, 
                                    buscando sempre expandir meu conhecimento e aplicar boas práticas de codificação. Com paixão por 
                                    resolver problemas e focado na experiência do usuário.</label>
                                <label className="dev03">03</label>
                                <label className="dev3">Habilidades</label>
                                <label className="description3">Tenho experiência como desenvolvedor solo de bots para Discord, 
                                    onde criei sistemas de atendimento automático entre 2016 e 2018. 
                                    Desde 2017, atuo como freelancer no desenvolvimento de front-ends para websites, 
                                    sempre buscando atender às necessidades dos clientes. Entre 2020 e 2024, 
                                    trabalhei no desenvolvimento avançado de cheats para jogos, 
                                    aplicando técnicas de bypass em sistemas de anti-cheat e adquirindo habilidades em engenharia reversa 
                                    e manipulação de memória. Atualmente, sou estudante de Ciência da Computação na UDESC, 
                                    onde continuo a expandir meus conhecimentos em programação e desenvolvimento de software.</label>
                                    
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext4">Sou um programador júnior com vastas habilidades em desenvolvimento front-end e back-end, 
                                    criação de bots e engenharia de software. Tenho experiência em várias linguagens e frameworks, 
                                    buscando sempre expandir meu conhecimento e aplicar boas práticas de codificação. Com paixão por 
                                    resolver problemas e focado na experiência do usuário.</label>
                                <label className="dev04">04</label>
                                <label className="dev4">Projetos</label>
                                <label className="description4">Ao longo da minha trajetória, trabalhei em diversos projetos. Embora muitos tenham se perdido ao longo do tempo, 
                                    o que permaneceu foi a experiência adquirida em cada um deles. Atualmente, estou reunindo e compartilhando alguns dos projetos 
                                    mais relevantes no meu GitHub e neste site. Clique no botão para conferir</label>
                                    <a href="/pt/projects" class="button" className="projetos-ir">        
                                        <span class="text">PROJETOS</span>
                                        <span class="arrow">→</span>
                                    </a>
                            </div>
                            <div className="carousel-item show">
                            <label className="titletext5">Sou um programador júnior com vastas habilidades em desenvolvimento front-end e back-end, 
                                    criação de bots e engenharia de software. Tenho experiência em várias linguagens e frameworks, 
                                    buscando sempre expandir meu conhecimento e aplicar boas práticas de codificação. Com paixão por 
                                    resolver problemas e focado na experiência do usuário.</label>
                                <label className="dev05">05</label>
                                <label className="dev5">Contato</label>
                                <label className="description5">Para entrar em contato conosco para esclarecer dúvidas ou obter mais informações, 
                                    por favor, clique no botão abaixo. Estarei disposto em responder suas perguntas. Não hesite em nos contatar!</label>
                                    <a href="/pt/contact" class="button" className="contact-ir">        
                                        <span class="text">CONTATO</span>
                                        <span class="arrow">→</span>
                                    </a>
                            </div>
                        </div>
                        {/* History line */}
                        <label className="indicator">SCROLL →</label>
                        <div className="history-line">
                               {slideLabels.map((label, index) => (
                                    <div 
                                        key={index} 
                                        className={`history-point ${currentSlide === index ? "active" : ""}`}
                                    >
                                    <span className="slide-number">0{index + 1}</span>
                                    <span className="slide-number2">{label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
 
            <div className="experience">

                    <div className="cards">    
                        <CardSlider/>
                        <CardSlider2/>  
                    </div>
                    <label className="title">SOBRE MIM</label>
                    <label class="surname">
                        <span>S</span>
                        <span>S</span>
                        <span>A</span>
                        <span>L</span>
                    </label>
                    <img src={chara} className="chara"></img>
                    <div className="chara-fade"></div>
                    <label className="about">Atualmente, tenho 18 anos e minha jornada no mundo do desenvolvimento começou por volta dos 9 anos, 
                        quando tive meu primeiro contato com a programação através do JavaScript. 
                        Desde então, meu interesse pela tecnologia e pela lógica por trás da programação 
                        só cresceu. Com o tempo, fui me aprofundando em outras linguagens e, hoje em dia, 
                        a minha principal linguagem de programação é C++/C. Atualmente estudo na UDESC CCT
                    </label>
  

            </div>

        </main>
    );
}

export default Home;
